import React from 'react'
import styled from 'styled-components'

import { TitleWrapper, TextWrapper } from './TitleAndTextStyle'

const ContentsWrapper = styled.div`
  margin-bottom: 48px;
`

type Props = {
  title?: string;
  detail?: any;
}

export const Description:React.VFC<Props> = ({ title, detail }) => {
  return (
    <>
      <TitleWrapper>
        <h2 className='background-title'>{title}</h2>
      </TitleWrapper>
      <TextWrapper>
        {detail.map((item, idx) => {
          return(
            <ContentsWrapper key={idx}>
              {item.heading &&
                <h3 className='heading-text'>{item.heading}</h3>
              }
              {item.text.map((text, idx) => {
                return (
                  <p key={idx} className='background-text'>{text}</p>
                )
              })}
            </ContentsWrapper>
          )
        })}
      </TextWrapper>
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import Layout from "../../../template/Layout"
import Head from "../../../head"

import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"
import { SectionTitle } from '../../common/SectionTitle'
import { RECRUIT_PAGE_STYLES, RESPONSIVE_STYLES } from '../../../AppConst'
import { RoundLinkButton } from '../../common/RoundLinkButton'
import { RecruitIndustries } from '../common/RecruitIndustries'

import { Background } from './BackGround'
import { Description } from './Description'
import { EssentialsTable } from './EssentialsTable'
import { FlowDescription } from './FlowDescription'

const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 960px;
  font-weight: 700;
  color: #222222;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
`

const OccupationWrapper = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
  .occupation {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
      font-size: 24px;
      line-height: 36px;
    }
  }
`

const TableWrapper = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
  border: 1px solid #AAAAAA;
`

const FlowWrapper = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      flex-direction: column;
      align-items: center;
    }
  & .btn-container {
    width: 440px;
    @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
      width: 100%;
      max-width: 335px;
    }
  }
`

const OtherOccupationWrapper = styled.div`
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
  background: #FFFFFF;
  padding: 160px 20px 160px;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    padding: 80px 20px 80px;
  }
`

type Props = {
  breadcrumbsData: BreadcrumbsData["values"];
  overView: any;
  jobDescription: any;
}

export const JobContents: React.VFC<Props> = (props) => {
  const { jobDescription, overView, jobDescription:{ descriptionText }, } = props
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
          title={`${descriptionText} | 採用情報 | ネクストライブ株式会社`}
          description={`ネクストライブ株式会社の${descriptionText}の採用情報ページです。募集要項、エントリー窓口を掲載しています。`}
      />
      <Container>
        <SectionTitle title="JOB DESCRIPTION" subTitle="募集要項"/>
        <OccupationWrapper>
          <h2 className='occupation'>{jobDescription.job}</h2>
        </OccupationWrapper>
        <Background title='募集背景' text={jobDescription.backGround} />
        <Description title='入社後の仕事内容' detail={jobDescription.description} />
        <TableWrapper>
          {jobDescription.detail.map((item, idx) => {
            return(
              <EssentialsTable key={idx} title={item.title} text={item.text} />
            )
          })}
        </TableWrapper>
        <FlowWrapper>
          <FlowDescription/>
          <ButtonWrapper>
            <div className='btn-container'>
              <RoundLinkButton title='カジュアル面談に申し込む' url='/contact/casualvisit'/>
            </div>
            <div className='btn-container'>
              <RoundLinkButton title='採用に応募する' url='/contact/recruit'/>
            </div>
          </ButtonWrapper>
        </FlowWrapper>
      </Container>
      <OtherOccupationWrapper>
        <RecruitIndustries 
          overView={overView} 
          title="他職種の募集"
          subTitle="JOB OPENING TYPE"
        />
      </OtherOccupationWrapper>
    </Layout>
  )
}

import styled from 'styled-components'

import { RECRUIT_PAGE_STYLES } from '../../../AppConst'

export const TitleWrapper = styled.div`
  background: #E9E9E9;
  height: 62px;
  padding: 16px 24px;
  box-sizing: border-box;
  ${RECRUIT_PAGE_STYLES.MARGIN_TOP_STYLE}
  & .background-title {
    font-size: 20px;
    line-height: 30px;
    margin: 0px;
  }
`

export const TextWrapper = styled.div`
  margin-top: 24px;
  & .background-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    margin: 0px;
  }
  & .heading-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    margin: 0px;
    ::before {
      content: "◆";
      position: relative;
      margin-right: 5px;
    }
  }
`
import React from 'react'
import styled from 'styled-components'

import { FlowContainer } from '../common/FlowContainer'
import { FlowWrapper } from '../common/FlowWrapper'

import { FlowNote } from '../common/FlowNote'

const Wrapper = styled.div`
  text-align: center;
  & .flow-title {
    margin: 0;
    font-size: 40px;
    line-height: 60px;
  }
`

const FlowItemList = [
  {
    number: `00`,
    title: `カジュアル面談`,
    detail: [
      `選考の前段階として、気軽に当社のことを知っていただく情報交換の場です。`,
      `当Webサイトのカジュアル面談お申込フォーム、または各求人媒体よりお申込みください。`,
    ]
  },
  {
    number: `01`,
    title: `エントリー`,
    detail: [
      `当Webサイトのエントリーフォーム、または各求人情報よりご応募ください。`,
    ]
  },
  {
    number: `02`,
    title: `書類選考`,
    detail: [
      `ご応募いただいた履歴書、業務経歴書を基に書類選考を行います。`,
    ]
  },
  {
    number: `03`,
    title: `一次面接（Web/対面）`,
    detail: [
      `採用担当による面接を行います。`,
      `堅苦しい面接は行いませんので、リラックスしてあなたのことを教えてください。`,
      `疑問に思うことはどんどんご質問していただいて構いません。`,
    ]
  },
  {
    number: `04`,
    title: `プレゼンテーション選考（対面）`,
    detail: [
      `事前にお伝えするテーマでプレゼンテーションをしていただきます。`,
      `あなたの熱い想いを披露して下さい。`,
      `※ 最終面接と同日に行う可能性もあります。`,
    ]
  },
  {
    number: `05`,
    title: `最終面接（対面）`,
    detail: [
      `社長または役員と面接を行います。`,
      `当社のビジョンとあなたの想いがマッチするかを確認して下さい。`,
    ]
  },
  {
    number: `06`,
    title: `NEXT ENGINEER PROJECT（対面）`,
    detail: [
      `現場レベルのプログラミングや、エンジニアに必要なスキルを学習していただきます。`,
      `ネクストライブの事務所で受講いただくので、入社前からメンバーとの交流ができます。`,
    ]
  },
  {
    number: `07`,
    title: `内定`,
    detail: [
      `入社日、給与待遇などの条件面の話合いをさせて頂きます。`,
      `諸条件に折り合えた場合に採用を決定し、入社手続きに関してご連絡いたします。`,
    ]
  },
  {
    number: `08`,
    title: `入社`,
    detail: [
      `入社時期はお気軽にご相談ください。`,
      `メンバーみんなで歓迎会をします！ `,
    ]
  },
]

export const FlowDescription = () => {
  return (
    <Wrapper>
      <h2 className='flow-title'>
        選考の流れ
      </h2>
      <FlowWrapper>
        {FlowItemList.map((item, idx) => {
          const { number, title, detail } = item;
          return (
            <FlowContainer number={number} title={title} detail={detail} index={idx} strongIndex={6} />
          )
        })}
      </FlowWrapper>
      <FlowNote />
    </Wrapper>
  )
}

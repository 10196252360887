import React from "react"

import { TitleWrapper, TextWrapper } from "./TitleAndTextStyle"

type Props = {
  title?: string
  text?: string[]
}

export const Background: React.VFC<Props> = ({ title, text }) => {
  return (
    <>
      <TitleWrapper>
        <h2 className="background-title">{title}</h2>
      </TitleWrapper>
      <TextWrapper>
        {text.map((item, idx) => {
          return (
            <p
              key={idx}
              className="background-text"
              dangerouslySetInnerHTML={{ __html: item }}
            />
          )
        })}
      </TextWrapper>
    </>
  )
}

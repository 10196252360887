import React from 'react'
import styled from 'styled-components'
import { RESPONSIVE_STYLES } from '../../../AppConst'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 48px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin-top: 24px;
  }
`
const Note = styled.p`
  margin: auto 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  color: #222222;
`

type Props = {}
export const FlowNote:React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <Note>※ 日時はご都合を考慮しますので、お気軽にご相談下さい。</Note>
      <Note>※ 各選考は3営業日以内に合否のご連絡をいたします。</Note>
    </Wrapper>
  )
}

import React from 'react'
import styled from 'styled-components'

import { RESPONSIVE_STYLES } from '../../../AppConst'

const StatusContainer = styled.div`
  display: flex;
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    flex-direction: column;
  }
  & .left-container{
    width: 30vw;
    background: #E9E9E9;
    border: 1px solid #AAAAAA;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
      width: 100%;
      box-sizing: border-box;
      height: 60px;
    }
    & .text {
      font-size: 20px;
      line-height: 30px;
      @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  & .right-container{
    width: 70vw;
    padding: 24px;
    box-sizing: border-box;
    border: 1px solid #AAAAAA;
    @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
      width: 100%;
      box-sizing: border-box;
    }
    & .text{
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      margin: 0px;

      & > a {
        color: #61B1C1;
      }

      @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
`

type Props = {
  title?: string;
  text?: string[];
}

export const EssentialsTable:React.VFC<Props> = (props) => {
  return (
    <StatusContainer>
      <div className='left-container'>
        <h2 className='text'>{props.title}</h2>
      </div>
      <div className='right-container'>
        {props.text.map((item, idx) => {
          return (
            <p key={idx} className='text' dangerouslySetInnerHTML={{ __html: item }} />
          )
        })}
      </div>
    </StatusContainer>
  )
}

import React from 'react'
import { graphql } from "gatsby"
import { JobContents } from '../../../components/recruit/challenge/index'

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `RECRUIT`, path: `recruit` },
  { text: `募集要項`, path: `recruit/challenge_engineer` },
]

export default function index ({data}) {
  const {
    site: { siteMetadata: { recruitData: { overView } } },
    site: { siteMetadata: { recruitData: { ChallengeEngineer: { JobDescription } } } },
  } = data
  const displayOverView = overView.filter((x) => {return x.url !== '/recruit/challenge_engineer'})
  return (
    <JobContents
        breadcrumbsData={breadcrumbsData}
        overView={displayOverView}
        jobDescription={JobDescription}
    />
  )
}

export const query = graphql`
    query ChallengeEngineerPageQuery {
      site {
        siteMetadata {
          recruitData {
            overView {
              job
              text
              url
            }
            ChallengeEngineer {
              JobDescription {
                job
                descriptionText
                backGround
                detail {
                  text
                  title
                }
                description {
                  heading
                  text
                }
              }
            }
          }
        }
      }
    }
  `